@import "./../../assets/scss/variable";

.stationWrapInfs {
    background: #f9f9f9;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.178);
    width: 100%;
    padding: 20px 20px ;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  
    & ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  
    & li {
      padding: 10px 0;
      color: #333;
    }
    .ant-select-selector{
      margin-bottom: 10px;
      .ant-select-selection-placeholder{
        position: absolute;
        top: 9px;
        left: 12px;
       }
    }
    .text-long{
      overflow-wrap: break-word;
    }
  }
.ant-form-item-required{
  display: flex;
  justify-content: center;
  align-items: end;
}
.order-history-main{
  max-width: 600px;
  margin: auto;
  padding: 0px 13px 20px;
  .box-order-history {
    background: var(--background-color);
    border: 1px solid var(--blue-ribbon);
    // padding: 0 0 20px;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    &-info{
      display: grid;
      gap: 10px;
      padding: 0 15px;
    }
    &__content {
      display: flex;
      align-items: center;
    }
    &--item {
      align-items: center;
      display: grid;
      width: 100%;
      gap: 15px;
    }
    &--content{
      gap: 15px;
      width: 100%;
      align-items: center;
    }
    &--icon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      gap:5px;
    }
  
    .text {
      margin-top: 18px;
    }
    &-text {
      color: #000;
      text-decoration: none;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      // max-width: 130px;
    }
    &-more{
      margin: 10px 0;
      &--icon{
        padding-right: 5px;
        color:var(--gray-color)
      }
    }
    & .mt-12 {
      margin-top: 12px;
    }
  }
  .title-upcase {
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #0870d9;
  }
  
  .text-important {
    margin-left: 5px;
  }
  
  .cursor {
    cursor: pointer;
  }
  
  .hint {
    letter-spacing: 0.0961538px;
    color: #d90027;
    max-width: 100%;
    margin-top: 0px;
    padding: 15px 26px;
  }
  
  .ant-space {
    display: block;
  }
  .more-opction {
    padding-left: 26px;
    padding-right: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin: 0px 15px;
    background-color: #f9f9fb;
    &__icon {
      span {
        cursor: pointer;
        margin: 5px;
      }
    }
  }
  
  .filterorder-history{
    position: absolute;
    top: 150%;
    right: 0;
    border-radius: 6px;
    background: #fff;
    width: 230px;
    padding: 1rem;
    box-shadow: 0 0.5px 2px 0px #00000066;
    li{
      list-style-type: none;
      padding: 6px 0;
    }
  }
}
.success-verified{
  padding: 4px 6px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--success-btn-color);
  background-color: $--color-E5FDD1;
}
.warning-verified{
  padding: 4px 6px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 3px;
  color: $--color-FAAD14;
  background-color: $--color-FFF5CE;
}
.ant-input{
  border-radius: 6px !important;
}

.ant-table-thead .ant-table-cell{
  text-transform: none !important;
  font-size: 14px;
}
.ant-tooltip-inner{
  background-color: var(--background-color) !important;
  border-radius: 6px !important;
}
.ant-tooltip-arrow-content::before{
  background: var(--background-color) !important;
}
.iconVehicleVerifiedInfo{
  width: 20px;
  height: 20px;
  text-align: center;
}
@media (max-width: 992px) {
  .box-order-history {
    padding: 10px 15px !important;
    .form-heigh {
      height: 1215px !important;
    }
  }
  .title-web{
    display: none;
  }
}
@media (max-width: 450px) {
  .startDate {
    margin-bottom: 15px;
  }
  .order-history-main {
    padding: 0px 0px 20px;
    .more-opction {
      padding-left: 15px;
      padding-right: 15px;
    }
    .order-history-widget{
      padding: 0px 0px 20px 0;
      &-more{
        padding: 0 10px;
      }
      &__detail{
        width: 99.5%;
        margin-top: 1px;
      }
      &__icon{
        // max-width: 70%;
      }
    }
    .box-order-history {
      padding: 0 0 0px !important;
      &-info{
        display: grid;
        // grid-template-rows: 1fr 1fr;
        grid-template-columns:none;
        gap: 0px;
      }
      &-more{
        // border-bottom: 1px dashed var(--border-color);
        padding-bottom: 8px;
      }
      &--item{
        flex-wrap: wrap;
        justify-content: none;
      }
      &--icon {
        justify-content: flex-end;
        cursor: pointer;
      }
    }
    .hint {
      padding: 15px;
    }
  }
  .title-web{
    display: none;
  }
  .more-opction{
    margin: 0px !important;
  }
}
 .vehicleRegistrationImg{
  max-width: 150px !important;
  max-height: 150px;
}
@media (max-width: 300px) {
  .order-history-main {
    .order-history-widget{
      padding: 0 0 15px;
      &__detail{
        gap:0.3rem;
        .plate{
          padding-left: 5px;
          padding-right: 5px;
        }
      }
      &__icon{
        max-width: 100%;
      }
      &-more{
        >div{
          width: 100%;
          display: grid !important;
        }
        svg{
          width: 2rem;
          height: 2rem;
        }
        .w-50{
          width: 100% !important;
        }
      }
    }
    .box-order-history{
      &-text {
        max-width: 167px;
      }
      &-text.timeOrder {
        max-width: 102px;
      }
      .plate{
        padding-left: 4px;
        padding-right: 4px;
      }
      &-more{
        &--icon{
          padding-right: 2px;
        }
      }
      &--content{
        gap:0.2rem
      }
    }
  }
  .title-web{
    display: none;
  }
}
@media (max-width: 380px) {
  .box-order-history {
    &-text {
      max-width: 185px;
    }
    &-text.timeOrder {
      max-width: 119px;
    }
  }
  .plate-color{
    .ant-row{
      flex-flow:row wrap !important;
      span.ant-radio + *{
        padding-right:4px !important;
        padding-left: 4px !important;
      }
    }
  }
}
